


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { required, minLength } from 'vuelidate/lib/validators'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import {
  Condition,
  StepFiveInterface,
  StepTwoInterface,
} from '@/store/modules/room.module'
import { namespace } from 'vuex-class'
const RoomStore = namespace('RoomStore')

const validations = {
  conditions: {
    required,
    minLength: minLength(1),
  },
}

@Component({
  name: 'StepFive',
  components: { FormGroup, Field, BaseButton, BaseIcon },
  validations,
})
export default class StepFive extends Vue {
  price: number | null = null
  error = ''
  active = false
  days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

  @RoomStore.State('conditions')
  conditions!: Condition[]

  @Watch('conditions')
  onConditionsChanged(val: Condition[]): void {
    if (this.active) {
      this.update()
    }
  }

  @Prop({ required: true })
  value!: StepFiveInterface

  @Prop({ default: false })
  isMobile!: boolean

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError && this.conditions.length > 0
  }

  public update(): void {
    this.$emit('input', {
      conditions: this.conditions,
    })
  }

  public activated(): void {
    this.active = true
  }

  public deactivated(): void {
    this.active = false
  }

  public getDays(weekdays: any): any {
    const newdays: any = []
    weekdays.forEach((day: any) => {
      for (let i = 0; i < 7; i++) {
        if (i === day - 1) {
          newdays.push(this.days[i])
        }
      }
    })
    return newdays.join(', ')
  }
}
